import React from "react";
import { NavLink } from 'react-router-dom';

import img43 from "../img/43.jpg";
import img18 from "../img/18.jpg";
import img19 from "../img/19.jpg";
import img20 from "../img/20.jpg";
import img21 from "../img/21.jpg";
import img22 from "../img/22.jpg";
import img23 from "../img/23.jpg";
import img24 from "../img/24.jpg";
import img25 from "../img/25.jpg";
import img26 from "../img/26.jpg";
import img27 from "../img/27.jpg";
import img28 from "../img/28.jpg";
import img29 from "../img/29.jpg";
import img30 from "../img/30.jpg";
import img32 from "../img/32.jpg";
import { SRLWrapper } from "simple-react-lightbox";

const Grid = () => {
  return (
    <div>
      <div className="main mx-auto">
        <div className="head">
          <h1 className="h1">Headind Head and Head</h1>
          <p>Some Photographs from our side:</p>
        </div>
        <SRLWrapper>
          <div className="gallery">
            <div className="img">
              <NavLink to={img43} data-lightbox="mygallery">
                {" "}
                <img src={img43} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img18} data-lightbox="mygallery">
                {" "}
                <img src={img18} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img19} data-lightbox="mygallery">
                {" "}
                <img src={img19} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img20} data-lightbox="mygallery">
                {" "}
                <img src={img20} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img21} data-lightbox="mygallery">
                {" "}
                <img src={img21} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img22} data-lightbox="mygallery">
                {" "}
                <img src={img22} />
              </NavLink>
            </div>
            
            <div className="img">
              <NavLink to={img24} data-lightbox="mygallery">
                {" "}
                <img src={img24} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img25} data-lightbox="mygallery">
                {" "}
                <img src={img25} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img26} data-lightbox="mygallery">
                {" "}
                <img src={img26} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img27} data-lightbox="mygallery">
                {" "}
                <img src={img27} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img28} data-lightbox="mygallery">
                {" "}
                <img src={img28} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img29} data-lightbox="mygallery">
                {" "}
                <img src={img29} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img30} data-lightbox="mygallery">
                {" "}
                <img src={img30} />
              </NavLink>
            </div>
            <div className="img">
              <NavLink to={img32} data-lightbox="mygallery">
                {" "}
                <img src={img32} />
              </NavLink>
            </div>
            

            
          </div>
        </SRLWrapper>
      </div>
    </div>
  );
};

export default Grid;
