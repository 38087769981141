import React from "react";
import Navvbar from "./Navvbar";
import Footer from "./Footer";
const Contact = () => {
  return (
    <>
    <div className="container mb-5"><Navvbar /></div>
    
      <div className="main-contact mt-5"></div>
      <div class="container">
        <div class="row">
          <div class="col-11 col-lg-4 contact-head">
            <h4 classname="contact-title">Abhay Sanjay&nbsp;,</h4>
            <p classname="contact-para">
              The Founder, The Owner and The Lead Photographer & Cinematographer
              at Pune Dusk Photography. After acquiring a professional degree in
              Film Making & Photography from Vancouver Film School, Canada . . .{" "}
              <br /> <br />
              Pune Dusk was initiated with the aim of unfolding unsaid stories
              using just a camera “AS AN IMAGE SPEAKS MORE THAN A THOUSAND
              WORDS! “<br /> <br />
              Pune Dusk helps you make the most memorable events of your life
              indelible in your mind as well as on paper. We specialise in
              Candid Photography and Cinematography. We at Pune Dusk know how
              unique and distinctive every couple is and our efforts are
              directed in portraying exactly that to the world.
              <br /> <br />
              We indeed “ CAPTURE WHAT IT FEELS LIKE AND NOT WHAT IT LOOKS LIKE!
              “
            </p>
          </div>
          <div class="col-12 col-lg-7">
            <div className="contact">
              <div className="contact-info mt-5 mb-5">
                <h5 className="contact-info-head">
                  <i>Contact me</i>
                </h5>
                <p className="contact-info-tel">
                  <strong>
                    <i>Tel: &nbsp;</i>
                  </strong>
                  9767998811/9049796464
                </p>
                <p className="contact-info-tel">
                  <strong>
                    <i>Email: &nbsp; </i>
                  </strong>
                  punedusk@gmail.com
                </p>
              </div>
              <div className="contact-info mb-5">
                <h5 className="contact-info-head">
                  <i>Studio</i>
                </h5>
                <p className="contact-info-add">
                  1 Ground Floor, Building Number 5A, Jhala Society, Near
                  Karishma Chowk, Kothrud, Pune
                </p>
              </div>
              <form class="row g-3 col-lg-8">
              <h2 class=" quote text-center text-light">Get a Quote</h2>
                <div class="col-md-6">
                  
                  <label for="inputEmail4" class="form-label text-light">
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    placeholder="Email"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputPassword4" class="form-label text-light">
                    Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="inputPassword4"
                    placeholder="Password"
                  />
                </div>
                <div class="col-12">
                  <label for="inputAddress" class="form-label text-light">
                    Address
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress"
                    placeholder="1234 Main St"
                  />
                </div>
                <div class="col-12">
                  <label for="inputAddress2" class="form-label text-light">
                    Address 2
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress2"
                    placeholder="Apartment, studio, or floor"
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputCity" class="form-label text-light">
                    City
                  </label>
                  <input type="text" class="form-control" id="inputCity" />
                </div>

                <div class="col-md-2">
                  <label for="inputZip" class="form-label text-light">
                    Zip
                  </label>
                  <input type="text" class="form-control" id="inputZip" />
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label class="form-check-label text-light" for="gridCheck">
                      Check me out
                    </label>
                  </div>
                </div>
                <div class="form-floating col-12 col-lg-5 mb-3">
                  <textarea
                    class="form-control col-12"
                    placeholder="Tell Us About Your Love Story"
                    id="floatingTextarea2"
                    style={{ height: "100px" }}
                  ></textarea>
                  <label for="floatingTextarea2">
                    <i>Tell Your Love Story</i>
                  </label>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <input
                      type="text"
                      class="form-control mb-3"
                      id="inputCity"
                      placeholder="Day 1 Event"
                    />
                    <input
                      type="text"
                      class="form-control mb-3"
                      id="inputCity"
                      placeholder="Day 2 Event"
                    />
                    <input
                      type="text"
                      class="form-control mb-3"
                      id="inputCity"
                      placeholder="Day 3 Event"
                    />
                  </div>
                </div>
                <div class="col-12">
                <button class="btn btn-lg btn-outline-danger" type="button">Request a Quote</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
