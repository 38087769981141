import React from "react";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./Home"
import Client from "./pages/Client";
import Pre from "./pages/Pre";
import Contact from "./pages/Contact";
import Films from "./pages/Films";

import Errors from "./pages/Error";

import { Route,Switch } from "react-router-dom";
function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/client" component={Client}></Route>
        <Route path="/pre" component={Pre}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/films" component={Films}></Route>
        
        <Route path="/" component={Errors}></Route>
      </Switch>
      
    </>
  );
}

export default App;
