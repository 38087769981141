import React from "react";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import SimpleReactLightbox from "simple-react-lightbox";
import Navbr from "./pages/Navbr";
import Mid from "./pages/Mid";
import Rotater from "./pages/Rotater";
import Grid from "./pages/Grid";
import Footer from "./pages/Footer";

function Home() {
  return (
    <>
      <Navbr />
      <Mid />
      <Rotater />
      <SimpleReactLightbox>
        <Grid />
      </SimpleReactLightbox>
      <Footer />
    </>
  );
}

export default Home;
