import React from 'react'
import Navvbar from "./Navvbar";
import Banner from "./Banner";
const Navbr = () => {
  return (
    <>
    <Navvbar />
    <Banner />
    </>
  )
}

export default Navbr
