import React from "react";

const Banner = () => {
  return (
    <>
      <div className="banner w-100 vh-100 d-flex justify-content-center align-items-center">
        
      </div>
    </>
  );
};

export default Banner;
