import React, { useState } from "react";
import logo from "../img/logo.jpeg";
import { NavLink } from "react-router-dom";
const Navvbar = () => {
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <>
      
      <nav
          className={`navbar navbar-expand-lg fixed-top navbar-dark p-md-3  ${
            navbar ? "navbar active" : "navbar"
          }`}
        >
      <div class="container">
      <NavLink
              to="#"
              className="navbar-brand text-uppercase "
            >
             Krrish Photography
            </NavLink>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
           <span class="navbar-toggler-icon"></span>
          </button>

            <div
              id="navbarSupportedContent"
              className="collapse navbar-collapse"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                <NavLink
                    to="/"
                    className="nav-link text-uppercase "
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/films"
                    className="nav-link text-uppercase text-light "
                  >
                    photos & films
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/pre"
                    className="nav-link text-uppercase text-light"
                  >
                    pre weddings
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/client"
                    className="nav-link text-uppercase text-light "
                  >
                    client albums
                  </NavLink>
                </li>
               
                <li className="nav-item">
                  <NavLink
                    to="/contact"
                    className="nav-link text-uppercase text-light"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
              <form class="form-inline my-2 my-lg-0 py-3">
              <button type="button" class="btn btn-danger btn-lg"><span className="px-2 py-3">CONTACT NOW</span></button>
              </form>
            </div>
          </div>
</nav>

      
    </>
  );
};

export default Navvbar;
