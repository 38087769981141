import React from "react";
import { NavLink } from "react-router-dom";
import Navvbar from "./Navvbar"
import img33 from "../img/33.jpg";
import img34 from "../img/34.jpg";
import img35 from "../img/35.jpg";
import img36 from "../img/36.jpg";
import img37 from "../img/37.jpg";
import img38 from "../img/38.jpg";
import img41 from "../img/41.jpg";
import img45 from "../img/45.jpg";
import img46 from "../img/46.jpg";

import Footer from "./Footer";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
const Pre = () => {
  return (
    <>
    <Navvbar />
    <SimpleReactLightbox>
    <SRLWrapper>
        <section className="pre pt-5">
        
          <div class="container-fluid">
          
            <div class="row pre-image">
            <div className="pre-title text-light mb-5">PRE WEDDINGS</div>
              <div class="column pre-column">
              
              <NavLink to={img33} data-lightbox="mygallery">
                {" "}
                <img src={img33} />
              </NavLink>
              <NavLink to={img34} data-lightbox="mygallery">
                {" "}
                <img src={img34} />
              </NavLink>
              <NavLink to={img35} data-lightbox="mygallery">
                {" "}
                <img src={img35} />
              </NavLink>
              

              </div> 
              <div class="column pre-column" data-lightbox="mygallery">
              <NavLink to={img36} data-lightbox="mygallery">
                {" "}
                <img src={img36} />
              </NavLink>
              <NavLink to={img37} data-lightbox="mygallery">
                {" "}
                <img src={img37} />
              </NavLink>
              <NavLink to={img38} data-lightbox="mygallery">
                {" "}
                <img src={img38} />
              </NavLink>
              </div>
              <div class="column pre-column" data-lightbox="mygallery">
              <NavLink to={img41} data-lightbox="mygallery">
                {" "}
                <img src={img41} />
                <NavLink to={img45} data-lightbox="mygallery">
                {" "}
                <img src={img45} />
              </NavLink>
              <NavLink to={img46} data-lightbox="mygallery">
                {" "}
                <img src={img46} />
              </NavLink>
              </NavLink>
              </div>
            </div>
          </div>
        </section>
      </SRLWrapper>
    </SimpleReactLightbox>
    <Footer />
    </>
  );
};

export default Pre;
