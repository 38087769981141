import React from "react";
import img2 from "../img/2.jpg";
import img49 from "../img/49.jpg";
import img14 from "../img/14.jpg";
const Mid = () => {
    
  return (
    <>
      <div className="row  main-cards ">
        <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto">
        <div className="card mx-auto" style={{width: "18rem"}}>
            <img src={img2} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Card title</h5>
              <p className="card-text">
                Some quick example text to build 
              </p>
          
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto">
          <div className="card mx-auto" style={{width: "18rem"}}>
            <img src={img49} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Creative photography</h5>
              <p className="card-text">
                Some quick example text to build 
              </p>
              
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto">
          <div className="card mx-auto" style={{width: "18rem"}}>
            <img src={img14} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">Card title</h5>
              <p className="card-text">
                Some quick example text to build 
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mid;
