import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFacebook} from "@fortawesome/free-brands-svg-icons"
import {faInstagram} from "@fortawesome/free-brands-svg-icons"
import {faYoutube} from "@fortawesome/free-brands-svg-icons"
import {faTwitter} from "@fortawesome/free-brands-svg-icons"
const Footer = () => {
    return (
        <>
           <footer class="mx-auto">
      <div class="social mx-auto">
        <h3>Contact Us</h3><br />
        <a classname="footer-mail">Mail : pranavshinde6773@gmail.com</a><br /><a>Phone No : 9579048982</a>
      <ul classname="">
        <li>
          <a href="#"><FontAwesomeIcon icon={faFacebook} className="fa-2x"></FontAwesomeIcon></a>
        </li>
        <li> <a href="#"><FontAwesomeIcon icon={faInstagram} className="fa-2x"></FontAwesomeIcon></a>
     </li>
     <li>
      <a href="#"><FontAwesomeIcon icon={faYoutube} className="fa-2x"></FontAwesomeIcon></a>
     </li>
     <li>
      <a href="#"><FontAwesomeIcon icon={faTwitter} className="fa-2x"></FontAwesomeIcon></a>
     </li>
     
      </ul>
      </div>
      <div class="soo">
        <p classname="footer-para">© 2021 Copyright: <a href="mailto:pranavshinde6773@gmail.com">Created by:Pranav</a></p>
      </div>
    </footer>
        </>
    )
}

export default Footer
