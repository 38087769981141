import React from 'react'
import img6 from "../img/6.jpg"
import img26 from "../img/26.jpg"
import img27 from "../img/27.jpg"
import img28 from "../img/28.jpg"
import img36 from "../img/36.jpg"
import img37 from "../img/37.jpg"
import img38 from "../img/38.jpg"
import img39 from "../img/39.jpg"
const Rotater = () => {
    
    return (
    <>
        <section className="circle">
        <div className="box">
            <div className="" style={{"--v":"1"}}><img src={img6} /></div>
            <div className="" style={{"--v":"2"}}><img src={img26} /></div>
            <div className="" style={{"--v":"3"}}><img src={img28} /></div>
            <div className="" style={{"--v":"4"}}><img src={img36} /></div>
            <div className="" style={{"--v":"5"}}><img src={img37}/></div>
            <div className="" style={{"--v":"6"}}><img src={img38} /></div>
            <div className="" style={{"--v":"7"}}><img src={img27} /></div>
            <div className="" style={{"--v":"8"}}><img src={img39} /></div>
           
        </div>
        </section>
    </>
    )
}

export default Rotater;
