import React from "react";
import { NavLink } from "react-router-dom";
import img11 from "../img/11.jpg";
import img12 from "../img/12.jpg";
import img50 from "../img/50.jpg";
import img53 from "../img/53.jpg";
import img3 from "../img/3.jpg";
import img4 from "../img/4.jpg";
import img6 from "../img/6.jpg";
import img16 from "../img/16.jpg";
import img18 from "../img/18.jpg";
import img30 from "../img/30.jpg";
import img8 from "../img/8.jpg";
import img9 from "../img/9.jpg";
import Navvbar from "./Navvbar";
import Footer from "./Footer";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
const Films = () => {
  return (
    <>
      <Navvbar />
      <SimpleReactLightbox>
        <SRLWrapper>
          <section className="films pt-5">
            <div class="container-fluid">
              <div class="card bg-dark text-white mb-5">
                <img src={img11} class="card-img" alt="..." />
                <div class="card-img-overlay">
                  <h5 class="card-title"></h5>
                  <p class="card-text"></p>
                  <p class="card-text"></p>
                </div>
              </div>
              <div class="row films-image">
                <div className="films-title text-light mt-5 mb-5">
                  PHOTOS & FILMS
                </div>
                <div class="column films-column">
                  <NavLink to={img3} data-lightbox="mygallery">
                    {" "}
                    <img src={img3} />
                  </NavLink>
                  <NavLink to={img4} data-lightbox="mygallery">
                    {" "}
                    <img src={img4} />
                  </NavLink>
                  <NavLink to={img53} data-lightbox="mygallery">
                    {" "}
                    <img src={img53} />
                  </NavLink>
                  <NavLink to={img18} data-lightbox="mygallery">
                    {" "}
                    <img src={img18} />
                  </NavLink>
                </div>

                <div class="column films-column" data-lightbox="mygallery">
                  <NavLink to={img6} data-lightbox="mygallery">
                    {" "}
                    <img src={img6} />
                  </NavLink>
                  <NavLink to={img30} data-lightbox="mygallery">
                    {" "}
                    <img src={img30} />
                  </NavLink>
                  <NavLink to={img8} data-lightbox="mygallery">
                    {" "}
                    <img src={img8} />
                  </NavLink>
                  <NavLink to={img16} data-lightbox="mygallery">
                    {" "}
                    <img src={img16} />
                  </NavLink>
                </div>
                <div class="column films-column" data-lightbox="mygallery">
                  <NavLink to={img9} data-lightbox="mygallery">
                    {" "}
                    <img src={img9} />
                  </NavLink>
                  <NavLink to={img12} data-lightbox="mygallery">
                    {" "}
                    <img src={img12} />
                  </NavLink>
                  <NavLink to={img50} data-lightbox="mygallery">
                    {" "}
                    <img src={img50} />
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
        </SRLWrapper>
      </SimpleReactLightbox>
      <Footer />
    </>
  );
};

export default Films;
