import React from "react";
import Navvbar from "./Navvbar"
import img3 from "../img/3.jpg";
import img4 from "../img/4.jpg";
import img24 from "../img/24.jpg";
import img25 from "../img/25.jpg";
import img34 from "../img/34.jpg";
import img40 from "../img/40.jpg";
import img49 from "../img/49.jpg";
import img51 from "../img/51.jpg";
import img52 from "../img/52.jpg";
import Footer from "./Footer";
const Client = () => {
  return (
    <>
    <div className=""><Navvbar /></div>
      <div className="clients container mt-5">
        
        <div className="row  client-card ">
        <div className="heading">
        <h1 className="client-title text-light">
          CLIENT <br />
          ALBUMS
        </h1>
        <p className="client-para text-light">
          "We Shoot What it Feels Like & Not What It Looks Like"
        </p>
        </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto mt-3">
            <div class="card-main" style={{width: "15rem"}}>
              <img src={img52} class="card-img-top image" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto  mt-3">
          <div class="card-main" style={{width: "15rem"}}>
              <img src={img3} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto  mt-3">
          <div class="card-main" style={{width: "15rem"}}>
              <img src={img4} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto mt-3">
          <div class="card-main" style={{width: "15rem"}}>
              <img src={img24} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto mt-3">
          <div class="card-main" style={{width: "15rem"}}>
              <img src={img25} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto mt-3">
          <div class="card-main" style={{width: "15rem"}}>
              <img src={img34} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto mt-3">
          <div class="card-main" style={{width: "15rem"}}>
              <img src={img40} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto mt-3">
          <div class="card-main" style={{width: "15rem"}}>
              <img src={img49} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xxl-4 work-container-subdiv mx-auto mt-3">
          <div class="card-main" style={{width: "15rem"}}>
              <img src={img51} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title text-light">Card title</h5>
                <p class="card-text text-light">
                  2 jan 2014
                </p>
                
              </div>
            </div>
          </div>
        </div>
        
      </div>
    <Footer />
    </>
  );
};

export default Client;
